import {
  IPCTSSessionContextData,
  Caption,
} from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.types';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import * as React from 'react';
import { ContentEditableEvent } from 'react-simple-wysiwyg';
import { GateWaySockets } from 'shared/hooks/axon/gateway.types';
import useGateWay from 'shared/hooks/axon/ipcts-gw/use-gateway.hook';
import usePFCGateWay from 'shared/hooks/axon/ipcts-pfc-gw/use-pfc-gateway.hook';
import {
  LogLevelType,
  usePostDynatraceLogs,
} from 'shared/hooks/dynatrace/use-dynatrace';
import { zeroWidthJoiner } from 'shared/utils/separator-joiners.util';

export type Transcription = {
  text: string;
  shardId: number;
  alternatives: string[];
};

export type SessionType = {
  id: number;
  token: string;
  title: string;
  description: string;
  enableTranscript: boolean;
  sessionStart: string;
  sessionStop: string;
  createdDt: string;
  sessionPassword: string;
};

export const IPCTSSessionContext = React.createContext<IPCTSSessionContextData>(
  {
    textForPhone: '',
    text: [],
    handleStopListening: () => Promise.resolve(),
    handleChange: () => {},
    handleStartListening: () => {},
    cardCaptionCallRef: null,
    isActiveSession: false,
    isTokenReady: false,
    customWord: [],
    setCustomWord: () => {},
    reset: () => {},
    addCustomCaption: () => {},
    correctionsCounter: 0,
    captionAreaFontSize: 24,
    setCaptionAreaFontSize: () => {},
    captionAreaLineHeight: 1,
    setCaptionAreaLineHeight: () => {},
    captionAreaFontFamily: '',
    setCaptionAreaFontFamily: () => {},
    accent: '',
    setAccent: () => {},
    isMuted: false,
    setIsMuted: () => {},
    mute: () => {},
    unmute: () => {},
    handleElementChange: () => {},
    sendShardCorrection: () => {},
  }
);

const IPCTSSessionProvider = ({ children }: { children: React.ReactNode }) => {
  const { captionShards, agentId, callId, setCallId } =
    React.useContext(LandingPageContext);
  const cardCaptionCallRef = React.useRef<HTMLDivElement | null>(null);

  const elements = React.useRef<Caption[]>([]);
  const [textForPhone, setTextForPhone] = React.useState('');
  const [isActiveSession, setIsActiveSession] = React.useState(false);
  const [isTokenReady, setIsTokenReady] = React.useState(false);
  const [accent, setAccent] = React.useState('');
  const [isMuted, setIsMuted] = React.useState(false);
  const [customWord, setCustomWord] = React.useState<any>([]);
  const correctionsCounterRef = React.useRef(0);
  const [captionAreaFontSize, setCaptionAreaFontSize] = React.useState(24);
  const [captionAreaLineHeight, setCaptionAreaLineHeight] = React.useState(32);
  const [captionAreaFontFamily, setCaptionAreaFontFamily] = React.useState('');
  const { log } = usePostDynatraceLogs();

  const {
    openPFCConnection,
    closePFCConnections,
    finishPFCCorrections,
    sendPFCCorrection,
  } = usePFCGateWay({ setCallId, captionShards });

  const { openConnection, closeConnections, sendCorrection, mute, unmute } =
    useGateWay();

  const sendShardCorrection = (
    socket: GateWaySockets,
    shardId: number,
    newText: string
  ) => {
    if (socket === GateWaySockets.PFCSocket) {
      sendPFCCorrection(shardId, newText);
    } else {
      sendCorrection(shardId, newText);
    }
  };

  const reset = () => {
    setIsMuted(false);
    elements.current = [];
  };

  const removeZeroWidthJoiner = React.useCallback((textToParse: string) => {
    let count = 1;
    return textToParse.split('').reduce((finalText, letter) => {
      const isEven = (count + 1) % 2 === 0;
      const isC = letter.search(zeroWidthJoiner.unicode);
      if (isC === -1) {
        return `${finalText}${letter}`;
      }
      count++;
      return isEven ? `${finalText}<c>` : `${finalText}</c>`;
    }, '');
  }, []);

  const handleElementChange = (
    element: HTMLElement,
    event: ContentEditableEvent
  ) => {
    if (element?.getAttribute('data-id') && event.type === 'input') {
      sendCorrection(
        Number(element.getAttribute('data-id')),
        removeZeroWidthJoiner(element.innerHTML)
      );
    }
  };

  const handleChange = (event: ContentEditableEvent) => {
    const element = (window as any)?.getSelection()?.anchorNode?.parentNode;
    handleElementChange(element, event);
  };

  const handleStartListening = async (socket: GateWaySockets) => {
    log({
      logLevelType: LogLevelType.INFO,
      callId,
      agentNumber: agentId,
      methodName: handleStartListening.name,
      parameters: { socket },
    });
    if (socket === GateWaySockets.PFCSocket) {
      openPFCConnection();
      setIsTokenReady(true);
    } else {
      await openConnection();
      setIsTokenReady(true);
    }

    setIsActiveSession(true);
  };

  const handleStopListening = React.useCallback(
    async (socket: GateWaySockets) => {
      if (socket === GateWaySockets.PFCSocket) {
        finishPFCCorrections();
        await closePFCConnections();
      } else {
        closeConnections();
      }
      return Promise.resolve();
    },
    [setIsActiveSession]
  );

  const addCustomCaption = (text: string) => {
    captionShards.current.push({
      text,
      id: -1,
      alternatives: [],
    });
    setTextForPhone(text);
  };

  (window as any).addCustomCaption = (text: string) => {
    addCustomCaption(text);
  };

  React.useEffect(() => {
    const unloadCallback = (e: BeforeUnloadEvent) => {
      const event = e;

      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  return (
    <IPCTSSessionContext.Provider
      value={{
        textForPhone,
        text: captionShards.current,
        isActiveSession,
        isTokenReady,
        cardCaptionCallRef,
        customWord,
        setCustomWord,
        handleStopListening,
        handleChange,
        handleStartListening,
        reset,
        addCustomCaption,
        correctionsCounter: correctionsCounterRef.current,
        captionAreaFontSize,
        setCaptionAreaFontSize,
        captionAreaLineHeight,
        setCaptionAreaLineHeight,
        captionAreaFontFamily,
        setCaptionAreaFontFamily,
        accent,
        setAccent,
        isMuted,
        setIsMuted,
        mute,
        unmute,
        handleElementChange,
        sendShardCorrection,
      }}
    >
      {children}
    </IPCTSSessionContext.Provider>
  );
};

export default IPCTSSessionProvider;
