import SVG from 'react-inlinesvg';
import styled from 'styled-components';

export const BackgroundContainer = styled.div`
`

export const Background = styled(SVG)`
position:fixed;
`;

export const MainDiv = styled.div`
  margin-top: 80px;
  color:#fff;
`;

export const Title = styled.div`
  text-align: center;
  h5 {
    font-size: 50px;
    font-weight: bold;
  }
`;

export const ProductSection = styled.div`
  -webkit-box-align: center;
  place-items: center;
  margin-top: 80px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

export const Button = styled.a`
  display: block;
  background-color: #28bfb2;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 240px;
`;
