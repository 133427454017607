import { Download } from 'modules/download/download.component';
import applicationRoutes from 'modules/routing/routes';
import {
  IRouteModule,
  RoutePathsFn,
  RoutesModules,
} from 'modules/routing/routes.types';

const downloadPageRoutes: RoutePathsFn = () => [
  {
    paths:[
      applicationRoutes.DOWNLOAD
    ],
    component: Download
  },
];

export const downloadPageModule = (): IRouteModule => ({
  name: RoutesModules.DOWNLOAD,
  routes: downloadPageRoutes(),
});
