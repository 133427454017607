import { Button, Select, Slider, Tabs } from 'antd';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

type CallControlsBodyProps = {
  tabsHidden?: boolean;
};

type SelectButtonProps = {
  isOpen?: boolean;
  optionsquantity?: number;
};

type TabButtonProps = {
  selected: boolean;
};

type ShowDotProp = {
  showDot?: boolean;
};

const buttonStyle = `
display: block;
line-height: 40px;
text-decoration: none;
width: 24px;
height: 24px;
border-radius: 50px;
z-index: 10;
`;

export const CallControlsContainer = styled.div`
  border: 1px solid rgba(4, 8, 9, 0.08);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 460px;
  max-width: 460px;
  overflow: hidden;
  align-items: center;
`;

export const CallControlsHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.callControlsHeader};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-family: ${({ theme }) => theme.fonts.stabil_grotesk_medium};
  height: 10%;
  max-height: 66px;
  width: 450px;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.callControlsHeaderText};
  font-family: ${({ theme }) => theme.fonts.inter_medium};
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const TextStyleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`;

export const SettingsSubtitle = styled.span`
  color: ${({ theme }) => theme.colors.callControlsHeaderTextPFC};
  font-family: ${({ theme }) => theme.fonts.inter_medium};
  line-height: 20px;
  font-size: 14px;
  display: flex;
  justify-content: left;
  padding: 0;
  margin-bottom: 6px;
`;

export const SettingsText = styled.span`
  color: ${({ theme }) => theme.colors.callControlsHeaderText};
  font-family: ${({ theme }) => theme.fonts.inter_medium};
  font-size: 14px;
  display: flex;
  justify-content: left;
  margin-bottom: 6px;
`;

export const SettingsSeparator = styled.div`
  border: 1px solid;
  height: 1px;
  width: 376px;
  margin-top: 50px;
  margin-left: 37px;
  margin-right: 37px;
`;

export const SliderContainer = styled.div`
  width: 50%;
  padding-right: 12px;
`;

export const SettingsSlider = styled(Slider)`
  width: 100%;
  margin-left: 0px;

  .ant-slider-track {
    background-color: ${({ theme }) => theme.colors.sliderTrack};
    height: 8px;
  }

  .ant-slider-rail {
    background-color: ${({ theme }) => theme.colors.sliderRail};
    height: 8px;
  }

  &:hover .ant-slider-track {
    background-color: ${({ theme }) => theme.colors.sliderTrack};
  }

  &:hover .ant-slider-rail {
    background-color: ${({ theme }) => theme.colors.sliderRail};
  }
`;

export const AudioTabs = styled(Tabs)`
  .ant-tabs-nav-list {
    color: ${({ theme }) => theme.colors.audioOptionColor};
    width: 100%;
    margin: 0 37px 0 37px;
  }

  .ant-tabs-tab {
    width: 50%;
    margin: 0;
    padding: 0;
    justify-content: center;
    &:hover {
      color: white;
    }
  }

  .ant-tabs-tab-btn {
    padding: 8px 0 8px 0;
    font-family: ${({ theme }) => theme.fonts.inter_medium};
    font-size: 14px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
  }

  .ant-tabs-ink-bar {
    background: white;
  }
`;

export const SelectDropDown = styled(Select)`
  margin: 4px 2px 0px 8px !important;
  overflow: hidden;
  height: 44px;
  .ant-select-selector {
    background-color: transparent !important;
    font-family: ${({ theme }) => theme.fonts.inter_regular};
    font-size: ${({ theme }) => theme.fontSizes.md};
    color: ${({ theme }) => theme.colors.handoffRequestButtonText};
    line-height: 24px;
    padding-left: 0 !important;
  }
  .ant-select-selection-item {
    text-align: left;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.handoffRequestButtonText};
  }
`;

export const SelectButton = styled(Button)<SelectButtonProps>`
  display: flex;
  background-color: transparent;
  border: 1px solid #393c3d;
  border-radius: 6px;
  height: 44px;
  width: 100%;
  padding: 0;
  margin-bottom: 6px;
  &:hover {
    background: transparent;
    border: 1px solid #fff;
    color: #fff !important;
  }

  &:focus {
    background: transparent;
    border: 1px solid #fff;
  }
`;

export const AudioSpan = styled.span`
  color: ${({ theme }) => theme.colors.callControlsHeaderText};
  font-family: ${({ theme }) => theme.fonts.inter_regular};
  font-size: 16px;
  display: flex;
  padding: 8px 0 8px 12px;
  margin: 0;
`;

export const SVGIcon = styled(SVG)`
  margin: 8px 0px 0px 8px !important;
  height: 20px;
  width: 20px;
  path {
    fill: ${({ theme }) => theme.colors.iconColorTwo} !important;
  }
`;

export const CallControlsBody = styled.div<CallControlsBodyProps>`
  width: 323px;
  border-bottom-left-radius: ${({ tabsHidden }) =>
    tabsHidden ? '8px' : '0px'};
  border-bottom-right-radius: ${({ tabsHidden }) =>
    tabsHidden ? '8px' : '0px'};
`;

export const ThemeOptionsContainer = styled.div<ShowDotProp>`
  width: 385px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 16px;
  ${({ showDot }) =>
    showDot &&
    `
  .selected::after {
    content: '';
    position: absolute;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-color: black;
    border-radius: 50%;
    margin: 12px;
  }`}
`;

export const ThemesTitle = styled.div`
  color: ${({ theme }) => theme.colors.whiteFontColor};
  font-family: ${({ theme }) => theme.fonts.inter_medium};
  margin-bottom: 21px;
  font-size: 14px;
  width: 50%;
  display: flex;
`;
export const AccentSection = styled.div`
  width: 100%;
  display: flex;
  margin: 24px 0px;
`;

export const Separator = styled.div`
  border: 1px solid;
`;

export const BlueBtn = styled.a`
  ${buttonStyle}
  background-color: ${({ theme }) => theme.colors.blueThemeColor};
`;

export const PinkBtn = styled.a`
  ${buttonStyle}
  background-color: ${({ theme }) => theme.colors.pinkThemeColor};
`;

export const GreenBtn = styled.a`
  ${buttonStyle}
  background-color: ${({ theme }) => theme.colors.greenThemeColor};
`;

export const YellowBtn = styled.a`
  ${buttonStyle}
  background-color: ${({ theme }) => theme.colors.yellowThemeColor};
`;

export const WhiteBtn = styled.a`
  ${buttonStyle}
  background-color: ${({ theme }) => theme.colors.whiteThemeColor};
`;

export const TabButtonsContainer = styled.div`
  border-radius: 8px;
  border: 1px solid var(--stroke-seperator-white-8, rgba(255, 255, 255, 0.08));
  display: flex;
  height: 44px;
  max-height: 53px;
  align-items: center;
  padding: 4px;
  width: 280px;
`;

export const TabButton = styled(Button)<TabButtonProps>`
  background-color: ${({ selected, theme }) =>
    selected
      ? theme.colors.callControlsTabSelected
      : theme.colors.callControlsTab};
  border-radius: 8px;
  border: none;
  box-shadow: none;
  color: ${({ selected, theme }) =>
    selected
      ? theme.colors.callControlsTabSelectedText
      : theme.colors.callControlsTabText};
  font-family: ${({ theme }) => theme.fonts.inter_medium};
  font-size: 16px;
  font-weight: 500px;
  line-height: 18px;
  height: 36px;
  width: 140px;
  &:focus {
    background-color: ${({ selected, theme }) =>
      selected
        ? theme.colors.callControlsTabSelected
        : theme.colors.callControlsTab};
    color: ${({ selected, theme }) =>
      selected
        ? theme.colors.callControlsTab
        : theme.colors.callControlsTabText};
  }
  &:hover {
    background-color: ${({ selected, theme }) =>
      selected
        ? theme.colors.callControlsTabSelected
        : theme.colors.callControlsTab};
    color: ${({ selected, theme }) =>
      selected
        ? theme.colors.callControlsTabSelectedText
        : theme.colors.callControlsTabText};
  }
  &:disabled {
    background-color: ${({ selected, theme }) =>
      selected
        ? theme.colors.callControlsTabSelected
        : theme.colors.callControlsTab};
    color: ${({ selected, theme }) =>
      selected
        ? theme.colors.callControlsTabSelectedText
        : theme.colors.callControlsTabText};
    cursor: default;
    &:hover {
      background-color: ${({ selected, theme }) =>
        selected
          ? theme.colors.callControlsTabSelected
          : theme.colors.callControlsTab};
      color: ${({ selected, theme }) =>
        selected
          ? theme.colors.callControlsTabSelectedText
          : theme.colors.callControlsTabText};
      cursor: default;
    }
  }
`;
