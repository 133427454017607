import ILLEGITIMATE from 'assets/icons/illegitimate_icon.svg';
import NOT_STARTED from 'assets/icons/no-answer-call.svg';
import NO_ANSWER from 'assets/icons/ringing.svg';
import SILENT from 'assets/icons/silent_icon.svg';
import { handleDispositionButtonAction } from 'modules/ipcts-call-session/components/controls/utils/handle-disposition.util';
import { IPCTSSessionContext } from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import * as React from 'react';
import { openErrorNotification } from 'shared/components/ui/alerts/notification.component';
import { ETerminationReasonCode } from 'shared/datasources/call-detail-record-service/entities.dto';

import * as Styled from './call-dispositions-menu.styled';

interface ICallDispositionsTwoPartyMode {
  setTimerSeconds: React.Dispatch<React.SetStateAction<number>>;
  setShowTimer: React.Dispatch<React.SetStateAction<boolean>>;
}

const TwoPartyMode = ({
  setTimerSeconds,
  setShowTimer,
}: ICallDispositionsTwoPartyMode) => {
  const { addCustomCaption } = React.useContext(IPCTSSessionContext);
  const [, setDispositionSelected] = React.useState<ETerminationReasonCode>(
    ETerminationReasonCode.Unknown
  );
  const handleDisposition = (selected: string) => {
    const flows = {
      [ETerminationReasonCode.NotStarted.toString()]: () => {},
      [ETerminationReasonCode.NoAnswer.toString()]: () => {
        setDispositionSelected(ETerminationReasonCode.NoAnswer);
      },
      [ETerminationReasonCode.Busy.toString()]: () => {
        addCustomCaption('(Busy)');
      },
      [ETerminationReasonCode.Silent.toString()]: () => {
        addCustomCaption('(Silence)');
        setTimerSeconds(60);
        setShowTimer(true);
      },
      [ETerminationReasonCode.SitTone.toString()]: () => {
        setDispositionSelected(ETerminationReasonCode.SitTone);
      },
      [ETerminationReasonCode.Illegit.toString()]: () => {
        openErrorNotification(
          'topRight',
          'Please message your supervisor and continue captioning while waiting for verification',
          'Illegitimate'
        );
        addCustomCaption('This is CaptionCall supervisor.');
        addCustomCaption(
          'I apologize about interrupting your captions. You should be aware that the CaptionCall Phone should only be used for captioning real-time conversations with another individual utilizing a telephone or checking answering machine messages.'
        );
        addCustomCaption(
          'If you have any questions regarding this policy, you agreed to in your end-user license agreement when you first received your phone, please contact Customer Support at 1-877-557-2227. At this time we will disconnect captions.'
        );
        setTimerSeconds(10);
        setShowTimer(true);
      },
    };
    const result = flows[selected];
    if (!result) {
      return;
    }
    handleDispositionButtonAction(selected);
    result();
  };

  return (
    <Styled.DispositionsContainer>
      <Styled.DispositionOption
        id="NotStarted"
        onClick={() =>
          handleDisposition(ETerminationReasonCode.NotStarted.toString())
        }
      >
        <Styled.SVGIcon src={NOT_STARTED} />
        <Styled.ButtonText>Not Started</Styled.ButtonText>
      </Styled.DispositionOption>
      <Styled.DispositionOption
        id="NoAnswer"
        onClick={() =>
          handleDisposition(ETerminationReasonCode.NoAnswer.toString())
        }
      >
        <Styled.SVGIcon src={NO_ANSWER} />
        <Styled.ButtonText>No Answer (Ringing)</Styled.ButtonText>
      </Styled.DispositionOption>
      <Styled.DispositionOption
        id="Busy"
        onClick={() =>
          handleDisposition(ETerminationReasonCode.Busy.toString())
        }
      >
        <Styled.SVGIcon src={ILLEGITIMATE} />
        <Styled.ButtonText>Busy</Styled.ButtonText>
      </Styled.DispositionOption>
      <Styled.DispositionOption
        id="Silent"
        onClick={() =>
          handleDisposition(ETerminationReasonCode.Silent.toString())
        }
      >
        <Styled.SVGIcon src={SILENT} />
        <Styled.ButtonText>Silent</Styled.ButtonText>
      </Styled.DispositionOption>
      <Styled.DispositionOption
        id="SitTone"
        onClick={() =>
          handleDisposition(ETerminationReasonCode.SitTone.toString())
        }
      >
        <Styled.SVGIcon src={NO_ANSWER} />
        <Styled.ButtonText>SIT Tone</Styled.ButtonText>
      </Styled.DispositionOption>
      <Styled.DispositionOption
        id="Illegit"
        onClick={() =>
          handleDisposition(ETerminationReasonCode.Illegit.toString())
        }
      >
        <Styled.SVGIcon src={ILLEGITIMATE} />
        <Styled.ButtonText>Illegitimate</Styled.ButtonText>
      </Styled.DispositionOption>
    </Styled.DispositionsContainer>
  );
};

export default TwoPartyMode;
