import { landingPageModule } from 'modules/landing-page/landing-page.module';
import { IRouteModule, RoutesModules } from 'modules/routing/routes.types';
import { downloadPageModule } from 'modules/download/download.module'
import { config } from 'shared/config/cts.config';

const routesArray: IRouteModule[] = [];
const downloadRoute: IRouteModule = downloadPageModule();

// if build type is production and electron api is null only load download route
if(config.BUILD_TYPE === 'production' && !window?.electronAPI){
  downloadRoute.routes[0].paths.push('/');
} else {
  routesArray.push(landingPageModule())
}
routesArray.push(downloadRoute);

const RoutesModule = (): IRouteModule[] => routesArray
export default RoutesModule;
